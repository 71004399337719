








































































































































































































































.addlist {
    .el-icon-plus:before {
        content: "\e6d9";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.table-switch {
    display: flex;
    align-items: center;
    justify-content: center;
    .el-switch__core {
        width: 40px !important;
    }
    .el-switch__core:after {
        background-color: #fff;
    }
    span {
        line-height: 20px;
        margin-left: 5px;
    }
}

.operationControl > div > div > span {
    min-width: 6rem;
}
